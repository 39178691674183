<template>
    <div id="botao-voltar">
        <button @click="$emit('fechar-atividade')">
            < voltar</button>
    </div>
    <div id="Atividade">
        <div id="head">
            <div id="Titulo">
                <h2>{{ dadosAtividade.nome }}</h2>
            </div>
            <div id="datas">
                <h6>{{ converterDatas(dadosAtividade.dataInicio) }} - {{ converterDatas(dadosAtividade.dataFim) }}</h6>
            </div>
        </div>
        <div id="conteudo">
            <div id="infos">
                <div id="tipo">
                    <h5>Tipo: {{ dadosAtividade.tipo }}</h5>
                </div>
                <div id="modalidade">
                    <h5>Modalidade: {{ dadosAtividade.modalidadeExecucao }}</h5>
                </div>
                <div id="local">
                    <h5>Local: {{ dadosAtividade.local }}</h5>
                </div>
                <div id="acao">
                    <!--<h5>Ação Responsável: {{ dadosAtividade.acao }}</h5>-->
                </div>
                <div id="descricao">
                    <h5>Descrição: </h5>
                    <h6>{{ dadosAtividade.descricao }}</h6>
                </div>
            </div>
            <div id="img">
                <figure>
                    <img :src="dadosAtividade.imagemUrl">
                </figure>
            </div>
        </div>
        <div v-show="!isInscrito">
            <div class="confirmacao" v-if="dadosAtividade.inscricao == false">
                <p>A atividade é aberta para todos</p>
            </div>
            <div class="confirmacao" v-else>
                <button @click="realizarInscricao" :disabled="carregando">Realizar Inscrição</button>
            </div>
        </div>
        <div class="confirmacao" v-show="isInscrito">
            Você já está inscrito nessa atividade
        </div>
        <div id="cancelamento" v-show="isInscrito">
            <button @click="cancelarInscricao">Cancelar Inscrição</button>
        </div>
    </div>
</template>

<script setup>
import { jwtDecode } from 'jwt-decode';
import { ref, watchEffect, computed, getCurrentInstance } from 'vue'

const carregando = ref(false);

const emit = defineEmits(["titulo", "fechar-atividade"])

const dadosAtividade = computed(() => props.atividade || {
    nome: "Nome Padrão",
    tipo: "Tipo padrão",//Estou usando a variavel 'tipo' para refênciar que tipo de atividade é. Ex: minucurso, oficina, palestra, etc...
    local: "Local Padrão",
    //acao: "Ação", //Ação responsável pela atividade
    descricao: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ac venenatis neque, sit amet facilisis sapien. Mauris in elit ligula. Maecenas volutpat pretium ex et congue. Morbi ante metus, lobortis vel dui quis, bibendum volutpat risus. Quisque pulvinar massa nec lobortis interdum. Suspendisse arcu nulla, ullamcorper vitae tellus quis, varius ultrices nunc. Pellentesque fermentum semper laoreet. In sagittis metus quis blandit consequat. Proin rutrum sem sed nunc faucibus, id interdum felis blandit. Donec porta malesuada tincidunt. Quisque vel euismod nisl. Quisque tempor suscipit sem, et lobortis ligula lacinia sed. In quis blandit lacus, in sagittis sapien. Duis sit amet porta risus, id porttitor dui. Maecenas pretium augue vel sapien dictum, sit amet pharetra purus hendrerit. Curabitur tempus, risus eu pellentesque iaculis, metus purus lacinia enim, non auctor elit augue sed nibh.",
    modalidade: "Presencial",
    //inscricao: true, //A atividade precisa de inscrição ou se é uma atividade livre
    dataInicio: "05/10/2024",
    dataFim: "10/10/2024",
    imagemUri: "https://burst.shopifycdn.com/photos/working-from-home.jpg?width=1000&format=pjpg&exif=0&iptc=0"
})

function converterDatas(data) {
    data = new Date(data);

    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
}

var isInscrito = ref(false) //Se o usuario já está incrito na atividade ou não
const { proxy } = getCurrentInstance();

async function realizarInscricao() {
    const userId = getUserId();
    const atividadeId = props.atividade.id
    try {
        const response = await proxy.$api.post('/participantes-atividades', {
            atividadeId: atividadeId,
            usuarioId: userId
        })
        console.log(response,)
    } catch (error) {
        console.log(error);
    } analiseInscricao()
}
function cancelarInscricao() {
    isInscrito.value = false
}

const props = defineProps({
    isAtiva: Boolean, //Recebe um booleano de Atividades.view que indica se a página de Atividade está ativa na tela ou não
    atividade: Object
})

watchEffect(() => {
    if (props.isAtiva && dadosAtividade.value) { //Se a tela de atividade estiver ativa
        sessionStorage.setItem('paginaHome', dadosAtividade.value.nome); //Define o titulo da página ativa
        emit('titulo') //envia o titulo para Atividades.vue
        console.log(dadosAtividade.value);
        analiseInscricao()
    }
})

function getUserId() {
    const token = window.localStorage.getItem('auth_token');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.userId;
        } catch (error) {
            console.error('Erro ao decodificar o token:', error);
        }
    }
}

async function analiseInscricao(){
    isInscrito.value = false;
    carregando.value = true;
    const atividadeId = props.atividade.id
    const userId = getUserId();
    try{
        const response = await proxy.$api.get(`/participantes-atividades/${atividadeId}`,{
            params: {page: 0, size: 10}
        });
        response.data.content.some((user) => {
            if(user.usuarioId == userId){
                isInscrito.value = true;
                console.log('foi')
            }
        })
        carregando.value = false;
        console.log(response.data.content)
    } catch(error){
        console.log(error);
    }
}
</script>
<style scoped>
#Atividade {
    padding-top: 5%;
}

#head {
    border-bottom: 2px black solid;
    padding-bottom: 1%;
}

#conteudo {
    padding-top: 4%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#infos {
    width: 45%;
    padding-right: 5%;
    text-align: justify;
    border-right: dotted black 2px;
}

#infos div {
    margin-bottom: 5%;
}

#img {
    position: relative;
    overflow: hidden;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45vh;
    width: 50%;
    background-color: #e9e9e9;
    border-radius: 2rem;
}

#img h3 {
    position: absolute;
}

#img img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

#head {
    text-align: center;
}

#acao {
    padding-bottom: 5%;
    border-bottom: black solid 2px;
}

#descricao h5 {
    text-align: center;
}

.confirmacao {
    margin-bottom: 1%;
    display: flex;
    margin-top: 2%;
    height: 10vh;
    width: 100%;
    background-color: #e9e9e9;
    border: none;
    border-radius: 1.25rem;
    justify-content: center;
    align-items: center;
}

.confirmacao button {
    height: 100%;
    width: 100%;
    background-color: #e9e9e9;
    border: none;
    border-radius: 1.25rem;
}

.confirmacao button:hover {
    background-color: #d0d0d0;
    transition: 0.5s;
}

#cancelamento button {
    margin-bottom: 1%;
    height: 100%;
    width: 100%;
    background-color: red;
    color: white;
    border: none;
    border-radius: 1.25rem;
}
</style>