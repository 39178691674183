
<template>
    <div v-show="!noticiaSelecionada" v-for="noticia in noticias">
        <div class="conteudo">
            <button @click="abrirNoticia(noticia)">
                <span>
                    <span>{{noticia.titulo}}</span>
                    <span class="info">{{noticia.autorPublicacaoNome}}</span>
                </span>
            </button>
            <p>{{ converterDatas(noticia.dataPublicacao) }}</p>
        </div>
    </div>
    <div v-show="noticiaSelecionada">
        <NoticiaView @fechar-noticia="fecharNoticia" :isAtiva="noticiaSelecionada" @titulo="(noticia)=>{abrirNoticia(noticia.value)}" :dadosNoticia="noticiaAberta"/>
    </div>
</template>

<script setup>
import NoticiaView from '@/components/Noticia.vue';
import { getCurrentInstance, onMounted, reactive, ref, watchEffect } from 'vue';

const emit = defineEmits(["paginaAberta"])

var noticiaSelecionada = ref(false);
var noticiaAberta = ref({});

const props = defineProps({
    isAtiva: Boolean
})

watchEffect(() => {
    if(!props.isAtiva){
        noticiaSelecionada.value = false;
        emit('paginaAberta', false)
    }
})

function abrirNoticia(noticia){
    noticiaAberta.value = noticia;
    emit('paginaAberta', true)
    noticiaSelecionada.value = true;
}

function fecharNoticia(){
    noticiaSelecionada.value = false
    emit('paginaAberta', false)
}

function converterDatas(data) {
    data = new Date(data);

    const dia = String(data.getDate()).padStart(2, '0'); 
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
}

const noticias = reactive([]);

const {proxy} = getCurrentInstance();

async function carregarNoticias() {
    const response = await proxy.$api.get('/noticia', {
        params: {page: 0, size: 10}
    });
    response.data.content.map((noticia) => {
        noticias.push(noticia);
    })
    console.log(noticias)
}

onMounted(() => {
    carregarNoticias();
})
</script>
