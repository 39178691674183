<template>
  <div id="atividade" v-show="!atividadeSelecionada">
    <h6
    class="situacao"
    :class="{ 'aberta': analiseSituacao(dadosAtividade.dataFim) === 'Aberta', 'encerrada': analiseSituacao(dadosAtividade.dataFim) === 'Encerrada'}">{{ analiseSituacao(dadosAtividade.dataFim) }}</h6>
    <div class="card" style="width: 18rem;">
      <img id="img" class="card-img-top" :src="dadosAtividade.imagemUrl" alt="Card image cap">
      <div class="card-body">
        <h5 class="card-title">{{ dadosAtividade.nome.toUpperCase() }}</h5>
        <p class="card-text" style="text-align: center;">Inicio: {{ converterDatas(dadosAtividade.dataInicio) }}</p>
        <p class="card-text" style="border-bottom: 2px solid #34495E; text-align: center;">Fim: {{ converterDatas(dadosAtividade.dataFim) }}</p>
        <p class="card-text">Tipo: {{ dadosAtividade.tipo }}</p>
        <p class="card-text">Modalidade: {{ dadosAtividade.modalidadeExecucao }}</p>
        <p class="card-text">Local: {{ dadosAtividade.local }}</p>
        <p class="card-text">Descrição: {{ dadosAtividade.descricao }}</p>
        <div class="botoes">
          <button @click="$emit('atividadeSelecionada', dadosAtividade)" class="btn btn-primary">Saiba mais</button>
          <button @click="editarAtividade = true" class="btn btn-primary editar" v-show="isCoordenador && situacao === 'aberta'">Editar</button>
        </div>
      </div>
    </div>
  </div>
  <div v-show="editarAtividade">
    <editarAtividade @fechar-form-atividade="editarAtividade=false" @recarregarAtividades="$emit('recarregarAtividades')" :dados="dadosAtividade" :acaoId="acaoId"/>
  </div>
</template>

<script setup>
import { Date } from 'core-js';
import { ref } from 'vue';
import EditarAtividade from './EditarAtividade.vue';

const props = defineProps({
  dadosAtividade: Object,
  isInscrito: Boolean,
  realizarInscricao: Function,
  cancelarInscricao: Function,
  isCoordenador: Boolean,
  acaoId: String
});

var situacao = ref('');
var editarAtividade = ref(false);

function converterDatas(data) {
    data = new Date(data);

    const dia = String(data.getDate()).padStart(2, '0'); 
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
}

function analiseSituacao(data){
  if(new Date(data) <= new Date()){
    situacao = 'encerrada'; 
    return 'Encerrada';
  } else {
    situacao = 'aberta'
    return 'Aberta';
  }
}
</script>

<style scoped>
#atividade {
  padding-top: 4%;
  font-family: 'Poppins', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

.card {
  margin: 1.2rem;
  margin-top: 0;
  background-color: #e5dbfa;
  overflow: hidden;
  border-radius: 1rem;
}

.card-text {
  margin-bottom: 0;
}

.btn {
  margin-top: 5%;
  background-color: rgb(54, 32, 101);
  border-color: #362065;
}

.btn:hover{
  background-color: rgba(63, 22, 152, 0.7);
}

.situacao {
  color: #ffffff;
  margin: 0 0 -5% 1.2rem;
  padding: 2% 2% 2% 5%;
  width: 45%;
  height: 45px;
  border-radius: 15px 15px 0 0;
} 

.encerrada {
  background-color: red;
}

.aberta {
  background-color: green;
}

h5 {
  text-align: center;
  font-size: 1.1rem;
  color: #34495E;
  font-weight: 600;
  border-bottom: 1px solid #34495E;
}

p {
  color: #34495E;
  font-weight: 550;
}

#img {
  height: 25vh;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0  1rem 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  /* Ajustado para espaçamento */
}

#img{
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

#img:hover{
  transform: scale(1.05);
}

.botoes{
  display: flex;
  justify-content: space-between;
}

.editar{
  background-color: transparent;
  color: #362065;
}
</style>
