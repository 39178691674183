import axios from 'axios';

function interceptors() {
    const config = setting => setting;

    const error = erro => {
        const responseErrors = {
            401: "Não autorizado, precisa se autenticar!",
            403: "Recurso proibido para o tipo de acesso!"
        };
        const extractMessage = erro?.response?.data?.message || null;
        const statusCode = erro?.response?.status;

        if (statusCode === 401 || extractMessage?.match(/token inválido|token expirado/i)) {
            window.localStorage.removeItem("auth_token");
            window.postMessage("login-expirado");
        }

        if (responseErrors[statusCode]) {
            console.error(responseErrors[statusCode]);
        }

        return Promise.reject(erro.response);
    };

    return { config, error };
}

const api = () => {
    const apiBaseUrl = "https://gex.nerds.cloudns.be/api";

    const configAxios = axios.create({
        baseURL: apiBaseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    });

    configAxios.interceptors.request.use(request => {
        const token = window.localStorage.getItem("auth_token");
    
        if (token && !request.url.includes('/autenticacao')) {
            request.headers['Authorization'] = `Bearer ${token}`;
        }
        return request;
    });

    const { config, error: handleError } = interceptors();
    configAxios.interceptors.response.use(response => {
        return response;
    }, handleError);

    return configAxios;
}

export const apiPlugin = {
    install(app, options) {
        app.config.globalProperties.$api = api();
    }
}
