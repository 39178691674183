<template>
    <div>
        <div v-if="acoesEncerradas.length != 0" class="conteudo" v-for="acao in acoesEncerradas">
            <button @click.prevent="$emit('abrir-acao')">{{ acao.titulo }}</button>
        </div>
        <div v-else>
            <p style="font-size: 1.95rem; font-weight: 500;">Nenhuma ação encontrada.</p>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';

const acoesEncerradas = ref([]);
</script>