<template>
  <div  v-show="!atividadeSelecionada">
    <select class="select" v-model="tipoAtividade">
      <option value="minhasAtividades">Minhas Atividades</option>
      <option value="atividadesLivres">Atividades Livres(Disponíveis)</option>
      <option value="atividadesComInscricao">Atividades Com Inscrição(Disponíveis)</option>
    </select>
    <div class="ordenacao-e-filtros">
      <div class="filtros">
        <h5>Filtros: {{ filtroSelecionado }}</h5>
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Situação
          </button>
          <ul class="dropdown-menu">
            <li v-for="situacao in filtros.situacao"><button @click="filtroSelecionado = situacao">{{ situacao
                }}</button>
            </li>
          </ul>
        </div>
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Tipo
          </button>
          <ul class="dropdown-menu">
            <li v-for="tipo in filtros.tipo"><button @click="filtroSelecionado = tipo">{{ tipo
                }}</button></li>
          </ul>
        </div>
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Modalidade
          </button>
          <ul class="dropdown-menu">
            <li v-for="modalidade in filtros.modalidade"><button @click="filtroSelecionado = modalidade">{{ modalidade }}</button></li>
          </ul>
        </div>
        <div>
          <button @click="filtroSelecionado = '', console.log(atividadesFiltradas)" class="limpar-filtros">Limpar
            filtros</button>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-wrap: wrap">
      <div id="atividade" v-for="dadosAtividade in atividadesFiltradas">
        <h6 class="situacao"
          :class="{ 'aberta': analiseSituacao(dadosAtividade.dataFim) === 'Aberta', 'encerrada': analiseSituacao(dadosAtividade.dataFim) === 'Encerrada' }">
          {{ analiseSituacao(dadosAtividade.dataFim) }}</h6>
        <div class="card" style="width: 18rem;">
          <img id="img" class="card-img-top" :src="dadosAtividade.imagemUrl" alt="Card image cap">
          <div class="card-body">
            <h5 class="card-title">{{ dadosAtividade.nome }}</h5>
            <p class="card-text" style="text-align: center;">Inicio: {{ converterDatas(dadosAtividade.dataInicio) }}</p>
            <p class="card-text" style="border-bottom: 2px solid #34495E; text-align: center;">Fim: {{
              converterDatas(dadosAtividade.dataFim) }}</p>
            <p class="card-text">Tipo: {{ dadosAtividade.tipo }}</p>
            <p class="card-text">Modalidade: {{ dadosAtividade.modalidadeExecucao }}</p>
            <p class="card-text">Local: {{ dadosAtividade.local }}</p>
            <p class="card-text">Descrição: {{ dadosAtividade.descricao }}</p>
            <div class="botoes">
              <button @click="atividadeSelecionada = true, atividadeAberta = dadosAtividade" class="btn btn-primary">Saiba mais</button>
              <button @click="editarAtividade = true" class="btn btn-primary editar"
                v-show="isCoordenador && situacao === 'aberta'">Editar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="atividadeSelecionada">
    <Atividade @fechar-atividade="fecharAtividade" :isAtiva="atividadeSelecionada" @titulo="abrirAtividade" :atividade="atividadeAberta"/>
  </div>
</template>
<script setup>
import { getCurrentInstance, reactive, ref, watchEffect, computed, onMounted } from 'vue';
import Atividade from '@/components/Atividade.vue';

const emit = defineEmits(["paginaAberta"])

const props = defineProps({
  isAtiva: Boolean //Recebe um booleano de HomeView que defina a página como visível ou não
})

var atividadeSelecionada = ref(false);
var tipoAtividade = ref('minhasAtividades');
const atividades = ref([]);
const atividadeAberta = ref(null);
const { proxy } = getCurrentInstance();

const filtros = {
  situacao: ['Aberta', 'Encerrada'],
  modalidade: ['PRESENCIAL', 'ONLINE', 'HIBRIDA'],
  tipo: ['CURSO', 'MINICURSO','EVENTO', 'OFICINA', 'ENCONTRO']
}

var filtroSelecionado = ref('')

onMounted(() => {
  carregarAtividades()
})

watchEffect(() => {
  if (!props.isAtiva) { //Se a página não estiver ativa
    atividadeSelecionada.value = false;
    emit('paginaAberta', false)
  } else {
    carregarAtividades();
  }
})

function abrirAtividade() {
  atividadeSelecionada.value = true;
  emit('paginaAberta', true)
}

function fecharAtividade() {
  atividadeSelecionada.value = false;
  emit('paginaAberta', false)
}

var situacao = ref('');
var editarAtividade = ref(false);

function converterDatas(data) {
  data = new Date(data);

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

function analiseSituacao(data) {
  if (new Date(data) <= new Date()) {
    situacao = 'encerrada';
    return 'Encerrada';
  } else {
    situacao = 'aberta'
    return 'Aberta';
  }
}

async function carregarAtividades() {
  try {
    const response = await proxy.$api.get('/atividade', {
      params: { page: 0, size: 50 }
    })
    
    atividades.value = response.data.content

    console.log(atividades)
  } catch (error) {
    console.log(error);
  }
}

const atividadesFiltradas = computed(() => {
  return atividades.value.filter((atividade) => {
    return (atividade.modalidadeExecucao.includes(filtroSelecionado.value) ||
      atividade.tipo.includes(filtroSelecionado.value) || analiseSituacao(atividade.dataFim) == filtroSelecionado.value
    )
  })
})
</script>
<style scoped>
template {
  display: flex;
}

#atividade {
  padding-top: 4%;
  font-family: 'Poppins', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

.card {
  margin: 1.2rem;
  margin-top: 0;
  background-color: #e5dbfa;
  overflow: hidden;
  border-radius: 1rem;
}

.card-text {
  margin-bottom: 0;
}

.btn {
  margin-top: 5%;
  background-color: rgb(54, 32, 101);
  border-color: #362065;
}

.btn:hover {
  background-color: rgba(63, 22, 152, 0.7);
}

.situacao {
  color: #ffffff;
  margin: 0 0 -5% 1.2rem;
  padding: 2% 2% 2% 5%;
  width: 45%;
  height: 45px;
  border-radius: 15px 15px 0 0;
}

.encerrada {
  background-color: red;
}

.aberta {
  background-color: green;
}

p {
  color: #34495E;
  font-weight: 550;
}

#img {
  height: 25vh;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  /* Ajustado para espaçamento */
}

#img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

#img:hover {
  transform: scale(1.05);
}

.botoes {
  display: flex;
  justify-content: space-between;
}

.editar {
  background-color: transparent;
  color: #362065;
}

.select {
  width: 100%;
  border: none;
  border-bottom: 2px solid black;
  font-size: 30px;
}

.select option {
  font-size: medium;
}

.ordenacao-e-filtros {
  display: flex;
  width: auto;
  border-bottom: 1px solid rgb(211, 211, 211);
  margin-bottom: 1%;
}

.filtros {
  width: 20%;
  margin-right: 2%;
  margin-bottom: 1%;
  border-right: 2px solid #6F319A;
}

.btn-group {
  margin: 0 5% 5% 0;
}

.btn:hover {
  background-color: #451e61;
  transition: 500ms;
}

.btn.show {
  background-color: #6F319A;
}

.limpar-filtros {
  width: 70%;
  border: 2px solid #6F319A;
  color: #6F319A;
  border-radius: 15px;
}

.card-title {
  text-align: center;
  font-size: 1.1rem;
  color: #34495E;
  font-weight: 600;
  border-bottom: 1px solid #34495E;
}

.dropdown-menu button {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid black;
}
</style>