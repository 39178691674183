<template>
    <div id="botao-voltar">
        <button @click="$emit('fechar-noticia')">
            < voltar</button>
    </div>
    <div id="informacoes">
        <h2>{{ noticia.titulo }}</h2>
        <p>Data publicação: {{ converterDatas(noticia.dataPublicacao) }}</p>
        <!--<p v-if="noticia.editada">{{ noticia.dataEdicao }}</p>-->
        <p>Autor: {{ noticia.autorPublicacaoNome }}</p>
    </div>
    <div id="conteudo">
        <div class="imagem" v-if="noticia.imagemUrl != ''">
            <img :src="noticia.imagemUrl">
            <p style="color: grey; margin:0; padding: 0;">(Descrição imagem)</p>
        </div>
        <p>{{ noticia.texto }}</p>
    </div>
    <div id="links">
        Compartilhe:
        <button type="button" class="btn btn-primary">Instagram</button>
        <button type="button" class="btn btn-primary">X(Twitter)</button>
    </div>
</template>
<script setup>
import { computed, ref, watchEffect } from 'vue';

const emit = defineEmits(["titulo", "fechar-noticia"])

const props = defineProps({
    isAtiva: Boolean,
    dadosNoticia: Object
})

const noticia = computed(() => props.dadosNoticia || {
    titulo: 'Titulo padrão',
    dataPublicacao: '10/10/2024',
    autorPublicacaoNome: 'Autor padrão',
    imagemUrl: '',
    texto: 'Texto padrão'
})

watchEffect(() => {
    if (props.isAtiva && noticia.value) {
        console.log(noticia)
        sessionStorage.setItem('paginaHome', noticia.value.titulo);
        emit('titulo', noticia)
    }
})

function converterDatas(data) {
    data = new Date(data);

    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
}
</script>
<style scoped>
.imagem {
    float: right;
    overflow: hidden;
    width: 45%;
    margin: 0 0 2% 2%;
    padding-top: 0.5%;
}

.imagem img {
    border-radius: 2rem;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

#informacoes {
    margin: 5% 5% 2% 0;
}

#informacoes p {
    margin-bottom: 0;
}

#conteudo {
    border-top: black solid 2px;
    padding-top: 2%;
    text-align: justify;
}

#links {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1%;
    padding: 1%;
    border: solid black 1px;
    border-radius: 1rem;
}

#links button {
    margin: 0 0.5%;
}
</style>