<template>
  <div id="menu" v-show="menuIsVisivel">
    <div class="user-info">
      <h5>{{ userName }}</h5>
    </div>
    <div class="list-group mt-4">
      <div v-for="item in menu" :key="item.label" id="opcoes">
        <button          
          :class="{'list-group-item': opcaoSelecionada !== item.label, 'opcao-selecionada': opcaoSelecionada === item.label}"
          @click="emitirPagina(item)"
        >
          {{ item.label }}
        </button>
        <div v-show="paginaIsVisivel && opcaoSelecionada === item.label" id="pagina-aberta">
          {{ paginaAberta }}
        </div>
      </div>
    </div>
  </div>
  <div id="menu-toggle" :style="{ marginLeft: margemMenuToggle + '%'}">
    <button @click="toggleMenu">
      {{direcaoSinal}} </button>
  </div>
</template>

<script>
import { jwtDecode } from 'jwt-decode';
import eventBus from '@/eventBus';


export default {
  data() {
    return {
      userName: null,
      menu: [],
      margemMenuToggle: 20,
      direcaoSinal: '<', //indica direção do sinal do botão de recolher menu
      menuIsVisivel: true,
      opcaoSelecionada: '', 
      paginaAberta: '', //indica o titulo da página aberta para colocar no menu lateral
      paginaIsVisivel: false, // indica se a página aberta está visível ou não
    };
  },
  created() {
    this.initializeMenu();
  },
  mounted() {
    eventBus.on('paginaAberta', (isSelecionada) => {
      console.log("Evento recebido:", isSelecionada);
      this.paginaAberta = sessionStorage.getItem('paginaHome');
      this.paginaIsVisivel = isSelecionada;
    });
    eventBus.on('abrirSessao', (sessao) => {
      if (this.opcaoSelecionada != sessao){
        this.opcaoSelecionada = sessao;
      } else {
        this.opcaoSelecionada = '';
      }
      eventBus.emit('changeComponent', 'atividades');
    })
  },
  methods: {
    initializeMenu() {
      const token = window.localStorage.getItem('auth_token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const nomes = decodedToken.nome.split(' ');
          this.userName = nomes[0] + " " + nomes[nomes.length -1]
          this.setMenuForUser(decodedToken.scope);
        } catch (error) {
          console.error('Erro ao decodificar o token:', error);
        }
      }
    },
    setMenuForUser(scope) {
      if (scope === 'ADMINISTRADOR') {
        this.menu = [
          { label: 'Suas Ações', page: 'acoes' },
          { label: 'Atividades', page: 'atividades' },
          { label: 'Notícias', page: 'noticias' },
          { label: 'Meus Certificados e Declarações', page: 'declaracoes-e-certificados'},
        ];
      } else {
        this.menu = [
          { label: 'Suas Ações', page: 'acoes' },
          { label: 'Atividades', page: 'atividades' },
          { label: 'Notícias', page: 'noticias' },
          { label: 'Meus Certificados e Declarações', page: 'declaracoes-e-certificados'},
        ];
      }
    },
    emitirPagina(pagina) {
      eventBus.emit('changeComponent', pagina.page);
      if (this.opcaoSelecionada != pagina.label){
        this.opcaoSelecionada = pagina.label;
      } else {
        this.opcaoSelecionada = '';
      }
    },
    toggleMenu() {//mudar a direção do sinal do botão de recolhimento do Menu Lateral
      this.menuIsVisivel = !this.menuIsVisivel;

      if (!this.menuIsVisivel) {
        this.margemMenuToggle = 0;
        this.direcaoSinal = '>'
      } else {
        this.margemMenuToggle = 20;
        this.direcaoSinal = '<'
      }
    }
  }
};
</script>

<style scoped>
#menu {
  background-color: #362065;
  color: #ffffff;
  width: 20%;
  padding: 20px;
  overflow-y: auto;
}

#menu-toggle {
  display: flex;
  align-items: center;
  position: fixed;
  width: 25px;
  height: 100vh;
  border-radius: 0 0.5rem 0.5rem 0;
}

#menu-toggle button {
  justify-content: center;
  border: none;
  background-color: #1c1134;
  color: #ffffff;
  height: 10%;
  width: 25px;
  border-radius: 0 0.5rem 0.5rem 0;
}

.user-info {
  margin-top: 4vh;
  margin-bottom: 2vh;
  font-size: 1.2rem;
}

.list-group-item {
  background-color: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 10px 15px;
  min-height: 50px;
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.list-group-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.list-group-item,
.opcao-selecionada {
  display: flex;
  align-items: center; /* Alinha o conteúdo verticalmente */
  justify-content: flex-start; /* Mantém o texto alinhado à esquerda */
  text-align: left; /* Garante que o texto seja alinhado à esquerda */
  padding: 10px 15px;
  min-height: 50px;
  width: 100%;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.list-group-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.opcao-selecionada {
  background-color: #EEE0F2;
  color: #000000;
}

#pagina-aberta {
  background-color: #9443ac  ;
  margin-top: -5px;
  border-radius: 0 0 5px 5px;
  padding-left: 10%;
  font-size: 80%;
  color: #ffffff;
}

#opcoes{
  border-radius: 5px;
}
</style>
