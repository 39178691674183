<template>
    <div v-show="!acoesEncerradas && !acaoSelecionada">
        <div class="conteudo" v-for="(acao, index) in acoes" :key="index">
            <button class="botao-acao" @click="abrirAcao(acao)">{{ acao.titulo }}</button>
            <div id="acoes-filhas" v-show="acao.acoesFilhas && acao.acoesFilhas.length >= 0">
                <button class="botao-dropdown" v-show="!dropdownAtivo.includes(acao.titulo)"
                    @click="toggleDropdown(acao.titulo)">Ver ações filhas ▽</button>
                <div v-if="dropdownAtivo.includes(acao.titulo)" v-for="(acaoFilha, index) in acao.acoesFilhas"
                    :key="index" style="box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2)">
                    <button class="acao-filha" @click="abrirAcao(acaoFilha)">{{ acaoFilha.titulo }}</button>
                </div>
                <button class="botao-dropdown" v-show="dropdownAtivo.includes(acao.titulo)"
                    @click="toggleDropdown(acao.titulo)">△</button>
            </div>
        </div>
        <div v-if="acoes.length === 0">
            <p style="font-size: 1.95rem; font-weight: 500;">Nenhuma ação encontrada.</p>
        </div>
    </div>
    <div id="botao-acoes-encerradas" v-show="!acaoSelecionada">
        <button @click="acoesEncerradas = !acoesEncerradas">
            <h4 v-show="!acoesEncerradas">ver ações encerradas</h4>
            <h4 v-show="acoesEncerradas">ver ações ativas</h4>
        </button>
    </div>
    <div v-show="acoesEncerradas && !acaoSelecionada">
        <AcoesEncerradas @abrir-acao="acaoSelecionada = true" />
    </div>
    <div v-show="acaoSelecionada">
        <AcaoView :acao="acaoAtiva" @fechar-acao="fecharAcao" :isAtiva="acaoSelecionada" @atualizarTitulo="emit('paginaAberta', true)"/>
    </div>
</template>

<script setup>
import AcoesEncerradas from './AcoesEncerradas.vue';
import AcaoView from '@/components/Acao.vue';
import { ref, reactive, watchEffect, onMounted, getCurrentInstance } from 'vue';

const dropdownAtivo = reactive([]);

function toggleDropdown(nome) {
    if (!dropdownAtivo.includes(nome)) {
        dropdownAtivo.push(nome);
    } else {
        const index = dropdownAtivo.indexOf(nome);
        dropdownAtivo.splice(index, 1);
    }
}

const emit = defineEmits(["paginaAberta"]);
const props = defineProps({
    isAtiva: Boolean
});
const { proxy } = getCurrentInstance();

var acoesEncerradas = ref(false);
var acaoSelecionada = ref(false);
var acoes = ref([]);
var acaoAtiva = ref(null);

watchEffect(() => {
    if (!props.isAtiva) {
        acaoSelecionada.value = false;
        emit('paginaAberta', false);
    }
});

function abrirAcao(acao) {
    acaoAtiva.value = acao;
    acaoSelecionada.value = true;
    emit('paginaAberta', true);
}

function fecharAcao() {
    acaoAtiva.value = null;
    acaoSelecionada.value = false;
    emit('paginaAberta', false);
}

function obterUserId() {
    const token = window.localStorage.getItem('auth_token');
    if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.userId;
    }
    return null;
}

async function buscarAcoes() {
    const userId = obterUserId();
    if (!userId) {
        console.error('ID de usuário não encontrado no token.');
        return;
    }

    try {
        const response = await proxy.$api.get(`/usuarios/acoes/${userId}`);
        if (response.data && Array.isArray(response.data)) {
            // Adiciona uma ação filha fictícia a cada ação
            acoes.value = response.data.map(acao => {
                if (!acao.acoesFilhas) {
                    acao.acoesFilhas = [];
                }
                acao.acoesFilhas.push({
                    titulo: 'Ação Filha Fictícia', // Nome da ação filha fictícia
                    descricao: 'Esta é uma ação filha adicionada para testes.' // Descrição fictícia
                });
                acao.titulo = acao.titulo.replace(/"/g, '')
                return acao;
            });
        } else {
            console.error('Dados inesperados recebidos:', response.data);
            acoes.value = [];
        }
    } catch (error) {
        console.error('Erro ao buscar ações:', error);
        acoes.value = [];
    }
}

onMounted(() => {
    buscarAcoes();
});
</script>


<style scoped>
#botao-acoes-encerradas button {
    position: fixed;
    bottom: 0;
    right: 0;
    border: none;
    background-color: #362065;
    color: #e9e9e9;
    border-bottom: solid rgb(163, 163, 163) 2px;
    border-top-left-radius: 1rem;
}

#acoes-filhas {
    width: 100%;
}

.botao-dropdown {
    padding: 0.5%;
    border: none;
    background-color: transparent;
    color: #ffffff;
    width: 100%;
    text-align: center;
    font-size: 20px;
}

#acoes-filhas div {
    padding: 1%;
    border-radius: 1.2rem;
    font-weight: 500;
}

.acao-filha {
    padding: 1%;
    margin: 0;
    border: none;
    color: #ffffff;
    background-color: transparent;
    width: 100%;
    text-align: start;
    font-size: 20px;
}

#acoes-filhas div:hover {
    transition: 500ms;
    background-color: rgba(0, 0, 0, 0.2);
}

.conteudo {
    flex-direction: column;
    box-shadow: none;
}

.botao-acao {
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
}
</style>
