<template>
  <div id="cadastro">
    <button id="voltar" @click.prevent="$emit('fechar-form-atividade')"> voltar </button>
    <form class="form" @submit.prevent="handleSubmit">
      <h4>Cadastrar Atividade</h4>
      <div id="campos-form">
        <div class="mb-3">
          <label for="nome" class="form-label">Nome</label>
          <input type="text" id="nome" class="form-control" v-model="formData.nome" required />
        </div>

        <div class="mb-3">
          <label for="descricao" class="form-label">Descrição</label>
          <textarea id="descricao" class="form-control" v-model="formData.descricao" required></textarea>
        </div>

        <div class="mb-3">
          <label for="tipo" class="form-label">Tipo</label>
          <select id="tipo" class="form-select" v-model="formData.tipo" required>
            <option v-for="tipo in Campos.tipos" :value="tipo.value">{{ tipo.nome }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="modalidade-execucao" class="form-label">Modalidade</label>
          <select id="modalidade-execucao" class="form-select" v-model="formData.modalidadeExecucao" required>
            <option v-for="modalidade in Campos.Modalidades" :value="modalidade.value">{{ modalidade.nome }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="modalidade-inscricao" class="form-label">Modalidade de Inscrição</label>
          <select id="modalidade-inscricao" class="form-select" v-model="formData.modalidadeInscricao" required>
            <option v-for="modalidadeInscricao in Campos.ModalidadesInscricao" :value="modalidadeInscricao.value">{{ modalidadeInscricao.nome }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="local" class="form-label">Local</label>
          <input type="text" id="local" class="form-control" v-model="formData.local" required />
        </div>

        <div class="mb-3">
          <label for="dataInicio" class="form-label">Data de Início</label>
          <input type="date" id="dataInicio" class="form-control" v-model="formData.dataInicio" required />
        </div>

        <div class="mb-3">
          <label for="dataFim" class="form-label">Data de Fim</label>
          <input type="date" id="dataFim" class="form-control" v-model="formData.dataFim" required />
        </div>

        <div class="mb-3">
          <label for="imagem" class="form-label">Imagem</label>
          <input type="file" id="imagem" class="form-control" accept="image/*" @change="processImage" />
        </div>
        
        <div class="mb-3" v-show="formData.imagem.base64Data != ''">
          <label for="local" class="form-label">Créditos imagem</label>
          <input type="text" id="creditos-img" class="form-control" v-model="formData.imagem.creditosImagem" />
        </div>

      </div>
      <div id="botoes" style="justify-content: center;">
        <button id="enviar" type="submit" class="btn btn-primary center">Enviar</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    acaoId: { // Corrigido o nome
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        nome: "",
        descricao: "",
        tipo: "",
        status: "PROGRAMADA",
        modalidadeExecucao: "",
        modalidadeInscricao: '',
        local: "",
        dataInicio: "",
        dataFim: "",
        acaoExtensaoId: this.acaoId,
        imagem: {
          creditosImagem: "",
          mimeType: "",
          base64Data: "",
        },
      },
      Campos: {
        tipos: [
          {
            value: 'CURSO',
            nome: 'Curso'
          },
          {
            value: 'MINICURSO',
            nome: 'Minicurso'
          },
          {
            value: 'EVENTO',
            nome: 'Evento'
          },
          {
            value: 'OFICINA',
            nome: 'Oficina'
          },
          {
            value: 'ENCONTRO',
            nome: 'Encontro'
          }
        ],
        Modalidades: [
          {
            value: 'REMOTA',
            nome: 'Remota'
          },
          {
            value: 'PRESENCIAL',
            nome: 'Presencial'
          },
          {
            value: 'HIBRIDA',
            nome: 'Hibrida'
          }
        ],
        ModalidadesInscricao: [
        {
            value: 'NESCESSITA_INSCRICAO',
            nome: 'Com Inscrição'
          },
          {
            value: 'NAO_NESCESSITA_INSCRICAO',
            nome: 'Sem Inscrição'
          },
          {
            value: 'NAO_NESCESSITA_INSCRICAO_COM_FREQUENCIA',
            nome: 'Sem Inscrição(Com Frequência)'
          },
          {
            value: 'NAO_NESCESSITA_INSCRICAO_SEM_FREQUENCIA',
            nome: 'Sem Inscrição(Sem Frequência)'
          }
        ]
      }
    };
  },
  methods: {
    async handleSubmit() {
      try {
        console.log(this.formData);
        if(this.formData.imagem.base64Data == ''){
          this.formData.imagem = null;
        }

        const response = await this.$api.post("/atividade", this.formData);

        console.log("Resposta da API:", response.data);
        alert("Atividade criada com sucesso!");
        this.resetForm();
      } catch (error) {
        if (error.response) {
          console.error("Erro ao criar atividade:", error.response.data); // Mensagem de erro do backend
        } else {
          console.error("Erro genérico:", error);
        }
      }
    },
    processImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.formData.imagem.mimeType = file.type;
          this.formData.imagem.base64Data = reader.result.split(",")[1];
        };
        reader.onerror = () => alert("Erro ao processar a imagem.");
        reader.readAsDataURL(file);
      }
    },
    resetForm() {
      this.formData = {
        nome: "",
        descricao: "",
        status: "",
        tipo: "",
        modalidadeExecucao: "",
        modalidadeInscricao: '',
        local: "",
        dataInicio: "",
        dataFim: "",
        acaoExtensaoId: this.acaoId,
        imagem: {
          creditosImagem: "",
          mimeType: "",
          base64Data: "",
        }
      };
      document.getElementById("activityForm").reset();
    },
  },
};
</script>

<style scoped></style>
