
<template>
  <div v-for="noticia in props.noticias">
      <div class="conteudo">
          <button @click="abrirNoticia(noticia), $emit('noticiaSelecionada', noticia)">
              <span>
                  <span>{{noticia.titulo}}</span>
                  <span class="info">{{noticia.autorPublicacaoNome}}</span>
              </span>
          </button>
          <p>{{ converterDatas(noticia.dataPublicacao) }}</p>
      </div>
  </div>
</template>

<script setup>
import { reactive, ref, watchEffect } from 'vue';

const emit = defineEmits(["paginaAberta"])

var noticiaSelecionada = ref(false);
var noticiaAberta = ref({});

const props = defineProps({
  noticias: Object
})

watchEffect(() => {
  if(!props.isAtiva){
      noticiaSelecionada.value = false;
      emit('paginaAberta', false)
  }
})

function abrirNoticia(noticia){
  noticiaAberta.value = noticia;
  emit('paginaAberta', true)
  noticiaSelecionada.value = true;
}

function fecharNoticia(){
  noticiaSelecionada.value = false
  emit('paginaAberta', false)
}

function converterDatas(data) {
  data = new Date(data);

  const dia = String(data.getDate()).padStart(2, '0'); 
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}
</script>
